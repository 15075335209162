<template>
  <div>
    <portal to="page-top-title">Categories</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'contactLists-categories-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/contact-lists/categories" :url-query="{ with: ['group:id,name'] }" data-prop="categories" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Group</th>
          <th>Lists</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="category in categories" :key="`contactLists-categories-tr-${category.id}`" :value="category">
          <td>{{ category.id }}</td>
          <td>{{ category.name }}</td>
          <td>
            <router-link :to="{ name: 'categoryGroups-single', params: { id: category.group.id } }">{{ category.group.name }}</router-link>
          </td>
          <td>
            <badge title="Total">{{ category.lists_count_total }}</badge>
            <badge title="Active" color="green">{{ category.lists_count_active }}</badge>
            <badge title="Inactive" color="red">{{ category.lists_count_inactive }}</badge>
          </td>
          <td class="text-right">{{ category.created_at }}</td>
          <td class="text-right">{{ category.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'contactLists-categories-edit', params: { id: category.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'contactLists-categories-single', params: { id: category.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import contactListCategory from '@/modules/strubs/contactListCategory'

export default {
  name: 'ContactListsCategoriesList',
  metaInfo: {
    title: 'ContactLists | Categories'
  },
  components: {
    BoxTable,
    Btn,
    Badge
  },
  data () {
    return {
      categories: [contactListCategory]
    }
  }
}
</script>
